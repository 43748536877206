<template>
  <v-container>
    <v-row justify="center" justify-sm="start">
      <v-col cols="auto">
        <h1 class="my-4 text-h1">
          {{ $t('Adding') }}
          <span class="text-gradient">{{ $t('newDeposit', {context: 'genitive'}) }}</span>
        </h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        v-for="depositType in depositTypes"
        :key="depositType.id"
        class="d-flex"
        cols="auto"
      >
        <v-hover v-slot:default="{hover}">
          <v-card
            :class="`deposit-type deposit-type--${depositType.id}`"
            :disabled="depositType.disabled"
            :elevation="hover ? 0 : 0"
            :ripple="false"
            :to="depositType.id === 'design' ? null : {name: 'new-deposit', params: {type: depositType.id}}"
            class="px-4 py-5 d-flex flex-column text-decoration-none"
            dark
            max-width="345"
          >
            <div class="icon-dark-plate mx-2 my-4">
              <v-icon v-text="icons[depositType.id]" />
            </div>

            <h3 class="mt-8 mx-4 text-h3">
              {{ depositType.title }}
            </h3>
            <v-card-text class="flex-grow-1">
              {{ depositType.text }}
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="depositType.id === 'design'"
                :href="figmaPlugin.url"
                :ripple="false"
                class="btn-dark-border-gradient"
                target="_blank"
                download
                elevation="0"
                block
                dark
                rounded
              >
                {{ $t('Download plugin') }}
              </v-btn>
              <v-btn
                v-else
                :to="{name: 'new-deposit', params: {type: depositType.id}}"
                class="btn-dark-border-gradient"
                elevation="0"
                block
                dark
                rounded
              >
                {{ $t('Create', {object: $t(depositType.id, {context: 'accusative'})}) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import {mdiCogOutline, mdiCodeTags} from '@mdi/js'
import {mdiFigmaOutline, mdiFrame} from '@/assets/mdi-custom-icons'

export default {
  name: 'DepositNewSelectType',
  data () {
    return {
      icons: {
        implementation: mdiCodeTags,
        algorithm: mdiCogOutline,
        screens: mdiFrame,
        design: mdiFigmaOutline,
      },
    }
  },
  computed: {
    ...mapState({
      types: state => state.config.deposit?.types || {},
      figmaPlugin: state => state.config.app?.figma || {},
    }),
    depositTypes () {
      return Object.keys(this.types)
        .map(type => ({id: type, ...this.types[type]}))
        .filter(type => type.createInAccount || type.id === 'design')
        .sort((dt1, dt2) => dt1.order - dt2.order)
    }
  }
}
</script>

<style lang="scss" scoped>
.deposit-type {
  position: relative;
  transition: background-color 0.3s ease;

  & > * {
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover:before {
    opacity: 1;
  }

  &.deposit-type--design:before {
    background: url(../assets/deposit-figma-bg.png) no-repeat center;
    background-size: cover;
  }
  &.deposit-type--implementation:before {
    background: url(../assets/deposit-code-bg.png) no-repeat center;
    background-size: cover;
  }
  &.deposit-type--screens:before {
    background: url(../assets/deposit-screens-bg.png) no-repeat center;
    background-size: cover;
  }
}
</style>
